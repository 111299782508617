/*
#menu {
	.grid-container {
		@include breakpoint(small only) {
			background-color: get-color(menu-bg);
		}
	}
}
*/
.top-bar {
	position: relative;
	z-index: 1;
	padding: 0;
	.menu {
		.menu-text {
			/*padding: 0;*/
			a {
				padding: 0.3rem 1rem 0.41rem 1rem;
			}
		}
		a {
			color: get-color(menu-color);
		}
	}
	li {
		>a {
			background-color: get-color(menu-bg);
			@include breakpoint(small only) {
				background-color: #0156e4;
			}
			color: get-color(menu-color);
		}
		>a:hover {
			background-color: get-color(menu-hover-bg);
			@include breakpoint(small only) {
				background-color: #0156e4;
			}
			color: get-color(menu-hover-color);
		}
	}
	li.current {
		>a, >a:hover {
			background-color: get-color(menu-current-bg);
			@include breakpoint(small only) {
				background-color: #0156e4;
			}
			color: get-color(menu-current-color);
		}
	}
	ul ul {
		font-size: 1rem;
		background-color: get-color(menu-dropdown-bg);
		@include breakpoint(small only) {
			background-color: get-color(menu-current-bg);
		}
	}
	.dropdown.menu.medium-horizontal {
		> li.is-dropdown-submenu-parent {
			&.is-active > a::after, &.current > a::after {
				border-color: get-color(menu-arrow-hover-color) transparent transparent!important;
			}
		}
	}
	.is-dropdown-submenu {
		.is-dropdown-submenu-parent {
			&.is-active > a::after, &.current > a::after {
				border-color: transparent transparent transparent get-color(menu-arrow-hover-color) !important;
			}
		}
	}
	.drilldown {
		.is-drilldown-submenu-parent {
			&.is-active > a::after, &.current > a::after {
				border-color: transparent transparent transparent get-color(menu-arrow-hover-color) !important;
			}
		}
	}
	.drilldown {
		.is-drilldown-submenu-parent {
			> a:hover::after {
				border-color: transparent transparent transparent get-color(menu-arrow-hover-color) !important;
			}
		}
	}
	.drilldown .js-drilldown-back > a:hover::before {
		border-color: transparent get-color(menu-arrow-hover-color) transparent !important;
	}
}

.title-bar {
	/* margin: 0 -0.625rem 0 -0.625rem; */
	/* padding: 0.5rem 0.625rem 0.5rem 0.625rem; */
	position: relative;
	z-index: 2;
	/*background: get-color(menu-bg);*/
	background: #0156e4;
	color: get-color(menu-color);
	.title-bar-left a {
		color: #fff;
	}
}
.menu-icon::after {
	background: get-color(menu-color);
	box-shadow: 0 7px 0 get-color(menu-color), 0 14px 0 get-color(menu-color);
}

.medium-align-center {
	@include breakpoint(medium) {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
}
