//  Variations to Settings (palarang)
//	----------------------

$hover-color-primary: #dee2e2; // #FFFEAF c9fff2
$hover-color-secondary: #000066;
$background-color-primary: #339933;
$background-color-two: #84c084; /* web safe #99cc99; */
$background-color-secondary: #006666;
$primary-heading: $background-color-secondary;
$secondary-heading: $background-color-primary;

$foundation-palette: map-merge($foundation-palette, (
  menu-bg: #509fd2,
  menu-color: #fff,
  menu-border: #fff,
  menu-active-bg: #1779ba,
  menu-active-color: #fff,
  menu-current-bg: rgba(#fff, 0.2),
  menu-current-color: #28BFEE,
  menu-hover-bg: #1779ba,
  menu-hover-color: #fff,
  menu-arrow-color: #ddd,
  menu-arrow-hover-color: #fff,
  menu-dropdown-bg: rgba(0, 96, 180, 0.8),
  anchor-color: #1779ba,
));
$anchor-color: get-color(anchor-color);
$anchor-color-hover: scale-color($anchor-color, $lightness: +32%);
$button-background-hover: scale-color($button-background, $lightness: +32%);
$button-background-hover-lightness: 32%;
$card-divider-background: #470046;
//$card-border: 1px solid $sitehighlight-color;
$drilldown-arrow-color: get-color(menu-arrow-color);
$dropdownmenu-arrow-color: get-color(menu-arrow-color);
$dropdown-menu-item-color-active: get-color(menu-active-color);
$dropdown-menu-item-background-active: get-color(menu-hover-bg);
$menu-item-color-active: get-color(menu-active-color);
$menu-item-background-active: get-color(menu-active-bg);
$topbar-background: get-color(menu-bg);
$topbar-dropdown-background: get-color(menu-dropdown-bg);
//$blockquote-color: #33cc33 !important;
