// Bizazz (palarang)
// ------
//
//  Table of Contents:
//
//	1. Accordion
//	?. Buttons
//	2. Cards
//	3. Clearfloats
//	4. Content
//	5. div.section
//	6. Error
//	7. Figure
//	8. Icons
//	9. Image
//	10. Light Box
//	11. Lists
//	12. no-js breakpoints
//	13. seolink
//	?.	Tabs
//	14. Text
//	15. Tooltip
//
//	---- end table of contents ---

//	1.	Accordion
// ---------------------------------------------------------------------------1

.accordion-title {
color: #000;
padding: 10px 45px 10px 10px;
border: 1px solid #808080;
margin-bottom: 5px;
background-color: #fff; // without this line visited accordions show darkgrey on mouse out till next mouse event
&:hover {
	background-color: scale-color($hover-color-primary, $lightness: +72%);
	color: #007000;
	&:before {
		border-color: #007000;
	}
}
@media screen and (max-width: 26em) {
	font-size: 0.8rem;
}
}
.accordion-title:before {
content: '';
border: solid #000;
	border-top-width: medium;
	border-right-width: medium;
	border-bottom-width: medium;
	border-left-width: medium;
border-width: 0 3px 3px 0;
display: inline-block;
padding: 6px;
-webkit-transform: rotate(45deg);
transform: rotate(45deg);
position: absolute;
right: 2.25rem;
top: 45%;
@media screen and (max-width: 26em) {
	right: 1rem;
}
}
.is-active > .accordion-title::before {
-webkit-transform: rotate(-135deg);
transform: rotate(-135deg);
top: 55%;
}
//	?.	Buttons
//

.button {
	border-radius: 0px;
}

//	2.	Cards
//	--------------------------------------------------------------------------2

.card {
	background: #fff;
  border: 1px solid #333;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  margin: 1rem 0;
  overflow: hidden;
//  width: 300px;
	.card-divider {
//		img {
//			border-radius: 50%; // round picture
//		}
		&.primary {
			background-color: $primary-heading;
		}
		&.secondary {
			background-color: $secondary-heading;
		}
		&> {
			h1, h2, h3, h4, h5, h6, p {
				width: 100%;
			}
		}
	}
}
.corner-card {
	background: #fff;
  border: 1px solid #333;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  margin: 1rem 0;
  overflow: hidden;
	//.card-label {
	//	position: relative;
		.card-label {
			border-color: rgba(255,255,255,0) $dark-gray rgba(255,255,255,0) rgba(255,255,255,0);
			&.corner-label-left {
				border-color: $dark-gray rgba(255,255,255,0) rgba(255,255,255,0) ;
			}
		}
		&.primary {
			border-color: $primary-color;
			.card-label {
				border-color: rgba(255,255,255,0) $primary-color rgba(255,255,255,0) rgba(255,255,255,0);
				&.corner-label-left {
					border-color: $primary-color rgba(255,255,255,0) rgba(255,255,255,0) !important;
				}
			}
		}
		&.secondary {
			border-color: $secondary-color;
			.card-label {
				border-color: rgba(255,255,255,0) $secondary-color rgba(255,255,255,0) rgba(255,255,255,0);
				&.corner-label-left {
					border-color: $secondary-color rgba(255,255,255,0) rgba(255,255,255,0) !important;
				}
			}
		}
		&.success {
			border-color: $success-color;
			.card-label {
				border-color: rgba(255,255,255,0) $success-color rgba(255,255,255,0) rgba(255,255,255,0);
				&.corner-label-left {
					border-color: $success-color rgba(255,255,255,0) rgba(255,255,255,0) !important;
				}
			}
		}
		&.alert {
			border-color: $alert-color;
			.card-label {
				border-color: rgba(255,255,255,0) $alert-color rgba(255,255,255,0) rgba(255,255,255,0);
				&.corner-label-left {
					border-color: $alert-color rgba(255,255,255,0) rgba(255,255,255,0) !important;
				}
			}
		}
		&.warning {
			card-color: $warning-color;
			.card-label {
				border-color: rgba(255,255,255,0) $warning-color rgba(255,255,255,0) rgba(255,255,255,0);
				&.corner-label-left {
					border-color: $warning-color rgba(255,255,255,0) rgba(255,255,255,0) !important;
				}
			}
		}
		.card-label {
			border-style: solid;
			float:right;
			height: 0px;
			width: 0px;
//			position: absolute;
//			top: 0;
//			right: 0;
			border-width: 0 rem-calc(70) rem-calc(40) 0;
			.label-text {
				color: $white;
				font-size: 0.75rem;
				font-weight: bold;
				position: relative;
				right: rem-calc(-40);
				top: 2px;
				white-space: nowrap;
				transform: rotate(30deg);
			}
//			&.label-left {
//				left: 0;
//				border-width: rem-calc(40) rem-calc(70) 0 0;
//				.corner-label-text {
//					left: 0.5rem;
//					right: auto;
//					top: -1.6rem;
//					white-space: nowrap;
//					transform: rotate(330deg);
//				}
//			}
		}
  .corner-card-content{
	  padding: 0.5rem 1.5rem 0.875rem;
  }

  &.radius {
    border-radius: 0.6rem;
  }

  .label-text {
    color: #fff;
    font-size: 0.75rem;
    font-weight: bold;
    position: relative;
    right: -35px;
    top: 0;
    white-space: nowrap;
		-ms-transform: rotate(30deg);
		-webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }
	&:hover {
		background: #f0f0f0;
		&.alert {
			background: #fff0f0;
		}
		&.success {
			background: #f0fff0;
		}
		&.info {
			background: #f8f8ff;
		}
	}

//		.card-divider {
//			&.corner-padding {
//				padding-left: 2rem;
//				padding-right: 2rem;
//			}
//		}
//	}
}

//	3.	Clearfloats
//	--------------------------------------------------------------------------3

.clearfloats {
	clear: both;
}
.clearright {
	clear: right;
}
.clearleft {
	clear: left;
}

.clearfloats {
	clear: both;
}
.clearright {
	clear: right;
}
.clearleft {
	clear: left;
}

//	4.	Content
//	--------------------------------------------------------------------------4

.content {
	a[name] {
		color: $black;
		cursor: default;
	}
	a:not(.button):not([name]):hover {
		color: scale-color(get-color(anchor-color), $lightness: +32%);
		text-decoration: underline;
	}
}

//	5.	div.section
//	--------------------------------------------------------------------------5

div.section {
	padding: 1rem 0;
}

//	6.	Error
//	--------------------------------------------------------------------------6

.error {
	font-weight: bold;
	color: #ff0000;
}
#form-error {
	color: #ff0000;
	font-weight: bold;
}

//	7.	Figure
//	--------------------------------------------------------------------------7

figure {
	margin: 0;
	@media only screen and (max-width: 40em) {
		display: block;
		margin: 0 auto 5px auto;
		max-width: 100%;
		height: auto;
	}
}
figure.captionovertop, figure.captionoverbottom {
	position: relative;
}
figcaption {
	width: 100%;
	text-align: center;
}
figure.captionovertop figcaption {
	position: absolute;
	top: 0px;
	left: 0px;
	background-color: rgba(51, 51, 51, 0.8);
	color: #ffffff;
}
figure.captionoverbottom figcaption {
	position: absolute;
	bottom: 0px;
	left: 0px;
	background-color: rgba(51, 51, 51, 0.8);
	color: #ffffff;
	padding: rem-calc(3 0);
}

//	8.	Icons
// ---------------------------------------------------------------------------8

.right-arrow:after {
	font-family: "foundation-icons";
	content: "\f10b";
}
.telephone-icon:after {
	font-family: "foundation-icons";
	content: "\f1f8";
}
.mail-icon:after {
	font-family: "foundation-icons";
	content: "\f16d";
}
//.fi-comments {
//	font-size: 2rem !important;
//}

//	9.	Image
//	--------------------------------------------------------------------------9

.imgborder {
	border: solid 1px #333333;
}
.imgborderwhite {
	background: #ffffff;
	padding: 5px;
	border: solid 1px #e0e0e0;
}
.imgleft {
	float: left;
	margin: 0 10px 5px 0;
	@media only screen and (max-width: 25em) {
		float: none;
		display: block;
		margin: 0 auto 5px auto;
		text-align: center;
	}
}
.imgcentre {
	margin: 0 auto 0 auto;
}
.imgright {
	float: right;
	margin: 0 0 5px 10px;
	@media only screen and (max-width: 40em) {
		float: none;
		display: block;
		margin: 0 auto 5px auto;
		text-align: center;
	}
}
.img-shadow, .show-shadow {
	border: solid 1px #888;
	-moz-box-shadow: 4px 4px 6px 0px #888;
	-webkit-box-shadow: 4px 4px 6px 0px #888;
	box-shadow: 4px 4px 6px 0px #888;
}

//	10.	Light Box
//	-------------------------------------------------------------------------10

.clearing {
	.thumbnail {
		margin: 0 10px 10px 0;
	}
}
#overlay {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: #000000 url(/assets/img/spinner.gif) no-repeat scroll center center;
}
#lightbox {
	position: absolute;
	z-index: 99;
	max-width: 100%;
	border: solid 5px #ffffff;
	img {
		display: block;
	}
}

//	11. Lists
//	--------------------------------------------------------------------------11
ul.disc {
	list-style-type: disc;
}
ul.circle {
	list-style-type: circle;
}
ul.square {
	list-style-type: square;
}
ul.grid-x {
	list-style-type: none;
}

//	12.	no-js breakpoints
//	--------------------------------------------------------------------------12

.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }
  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}

//	13.	seolink
//	-------------------------------------------------------------------------13

a.seolink:link, a.seolink:visited, a.seolink:hover {
	color: #000000;
	text-decoration: none;
}

//	?.	Tabs
//	-------------------------------------------------------------------------?
$accordionTabColor: #000;
$accordionTabIsactiveColor: #fff;
$accordionTabHoverColor: #9dd7de;
$accordionTabBorderColor:green;
$accordionTabBackgroundColor:tomato;

.tabs { // was dl.tabs
	margin-left: 5px;
	border: 0;
	.tabs-title { // was dd
		margin-left: 5px;
	}
	a {
		border-top: 1px solid $accordionTabBorderColor;
		border-left: 1px solid $accordionTabBorderColor;
		border-right: 1px solid $accordionTabBorderColor;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		outline: none;
	}
}
.tabs-title > a {
	font-size: 1rem;
	padding: .825rem 1.25rem;
	color: $accordionTabColor;
	background: $accordionTabBackgroundColor;
	&:hover {
		background: $accordionTabHoverColor;
	}
}
.tabs-title > a[aria-selected='true'] {
		color: $accordionTabIsactiveColor;
		background: $accordionTabBorderColor;
	}
.tabs-content .tabs-panel { // was .tabs-content .content
//	@media only screen and (min-width: 40.063em) {
		border-top: solid 10px $accordionTabBorderColor;
//	}
	border-left: 1px solid $accordionTabBorderColor;
	border-right: 1px solid $accordionTabBorderColor;
	border-bottom: 1px solid $accordionTabBorderColor;
	padding: 10px;
//	@media only screen and (min-width: 40.063em) {
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
//	}
}
.tabs-content .tabs-panel { // was .accordion .content
	border-left: 1px solid $accordionTabBorderColor;
	border-right: 1px solid $accordionTabBorderColor;
	border-bottom: 1px solid $accordionTabBorderColor;
	padding: 15px;
}


//	14.	Text
//	-------------------------------------------------------------------------14

.redtext {
	color: #800000;
}
.orangetext {
	color: #c04000;
}
.highlight {
	background: #ffff00;
}
.normal {
	font-weight: normal;
}

//	15.	Tooltip
// --------------------------------------------------------------------------15

.tooltip {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: 150%;
	left: 50%;
	margin-left: -60px;
}
.tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: black transparent transparent transparent;
}
.tooltip:hover .tooltiptext {
	visibility: visible;
}
