// Override (palarang)
// --------
//
//  Table of Contents:
//
//	?.
//	1. Form and Avatar Loader
//	2. Moontree calculate
//	?. Read More
//  ?. Snowy Monaro Writers
//	3. Text styles
//	4. Off Canvas
//
//	---- end table of contents ---

//	1. Form and Avatar Loader
// ---------------------------------------------------------------------------1
#modal1 {
	margin-left: 50%;
}

form {
	textarea {
		&#comment_text {
			height: 125px;
		}
	}
	input {
		&#comment_precis_text {
			width: 35rem;
		}
	}
	.fieldset {
		border: 1px solid grey;
		background-color: #f2f2f2;
		#save_browser {
			margin-top: 15px;
		}
	}
	#form_inner {
		display: none;
	}
	// avatar loader
	.avatar-wrapper{
		position: relative;
		height: 200px;
		width: 200px;
		margin: 50px auto;
		border-radius: 50%;
		overflow: hidden;
		box-shadow: 1px 1px 15px -5px black;
		transition: all .3s ease;
		&:hover{
		transform: scale(1.05);
		cursor: pointer;
			}
		&:hover .profile-pic{
				opacity: .5;
		}
		.profile-pic {
			height: 100%;
			width: 100%;
			transition: all .3s ease;
			&:after{
//			font-family: FontAwesome;
//			content: "\f007";
				font-family: "foundation-icons";
				content: "\f1f8";
				top: 0; left: 0;
				width: 100%;
				height: 100%;
				position: absolute;
				font-size: 190px;
				background: #ecf0f1;
				color: #34495e;
				text-align: center;
			}
		}
		.upload-button {
			position: absolute;
			top: 0; left: 0;
			height: 100%;
			width: 100%;
			.fi-upload {
				position: absolute;
				font-size: 234px;
				top: -17px;
				left: 0;
				text-align: center;
				opacity: 0;
				transition: all .3s ease;
				color: #34495e;
			}
			&:hover .fi-upload{
				opacity: .9;
			}
		}
	}
}

//	2. Moontree calculate
// ---------------------------------------------------------------------------2

.spice {
	color: #c00000;
}
.nonspice {
    color: blue;
}
.bulk {
    color: #008000;
}
.calculatePics {
    color: #ffffff;
    background-color: DodgerBlue;
}

//	?.	Read More
// ---------------------------------------------------------------------------?

#text{
display:none;
}
button#toggle {
  user-select:none;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  cursor:pointer;
  border:none;
  padding:8px;
  font-size:20px;
}
#quick_comments {
	display: none;
	color: #1779ba;
}

//	?.	Snowy Monaro Writers
// ---------------------------------------------------------------------------?

.snowyMonaroWriters {
	#offCanvasLeft, #offCanvasRight {
		padding-left: 5px;
		top: 40px;
		li {
			list-style: none;
		}
	}

	.svgAnimate {
		width: 100%;
		height: 7vh;
		background-color: #fff;
		position: relative;
		margin: -10px 0 10px 0;
	}
	#logo {
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  transform: translate(-50%, -50%);
	  animation: fill 0.5s ease forwards 3s;
	}
	#flowRoot11 {
		animation: fill 1s ease forwards 3s;
	}
	#logo path:nth-child(1) {
	  stroke-dasharray: 440.8581237792969px;
	  stroke-dashoffset: 440.8581237792969px;
	  animation: line-anim 2s ease forwards;
	}
	#logo path:nth-child(2) {
	  stroke-dasharray: 135.60659790039062px;
	  stroke-dashoffset: 135.60659790039062px;
	  animation: line-anim 2s ease forwards 0.3s;
	}
	#logo path:nth-child(3) {
	  stroke-dasharray: 125.489501953125px;
	  stroke-dashoffset: 125.489501953125px;
	  animation: line-anim 2s ease forwards 0.6s;
	}
	#logo path:nth-child(4) {
	  stroke-dasharray: 176.73963928222656px;
	  stroke-dashoffset: 176.73963928222656px;
	  animation: line-anim 2s ease forwards 0.9s;
	}
	#logo path:nth-child(5) {
	  stroke-dasharray: 253.64772033691406px;
	  stroke-dashoffset: 253.64772033691406px;
	  animation: line-anim 2s ease forwards 1.2s;
	}
	#logo path:nth-child(6) {
	  stroke-dasharray: 135.60659790039062px;
	  stroke-dashoffset: 135.60659790039062px;
	  animation: line-anim 2s ease forwards 1.5s;
	}
	#logo path:nth-child(7) {
	  stroke-dasharray: 209.1616973876953px;
	  stroke-dashoffset: 209.1616973876953px;
	  animation: line-anim 2s ease forwards 1.8s;
	}

	@keyframes line-anim {
	  to {
	    stroke-dashoffset: 0;
	  }
	}
	@keyframes fill {
	  from {
	    fill: transparent;
	  }
	  to {
	    fill: #3171c1;
	  }
	}
}


//	3.	Text styles
// ---------------------------------------------------------------------------3

.mycolour {
	color: #c00000;
}
.mywhite {
	color: #ffffff;
}
#previous_reply {
	background: #fff;
}

//	4. Off Canvas
// ---------------------------------------------------------------------------4

#offCanvas {
	margin-right: 1rem;
	padding-right: 1rem;
	border-right: solid 1px $light-gray;
	&.position-left {
		width: 240px;
	}
	h4 {
		padding: 1rem 0.7rem 0 0.7rem;
	}
}
